.job-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px; /* Espaçamento entre os cards */
    justify-content: space-between; /* Distribui espaço uniformemente */
    padding: 20px;
    width: 80%;
  }

.job-card {
    width: 100%;
    max-width: 400px;
    border: 1px solid #ddd;
    flex-direction: row;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: #fff;
    overflow: hidden;
    font-family: 'Montserrat';
    margin-top: 20px;

  }
  
  .job-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #0056a4;
    color: #fff;
  }
  
  .job-logo img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .job-buttons a {
    text-decoration: none;
    margin-left: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 500;
  }

  .job-buttons a:hover{
    color: #fff;
  }
  
  .apply-button {
    background-color: #df622e;
    color: #fff;
  }
  
  .details-button {
    background-color: #fff;
    color: #0056a4;
    border: 1px solid #0056a4;
  }
  
  .job-card-body {
    padding: 1rem;
  }
  
  .job-card-body h3 {
    font-size: 18px;
    color: #0056a4;
    margin-bottom: 0.5rem;
    font-family: 'Montserrat';
    font-weight: bold;
  }
  
  .job-card-body p {
    font-size: 14px;
    color: #000000;
    font-family: 'Montserrat';
    font-weight: 400;
  }
  
  .job-card-body .highlight {
    color: #df622e;
    font-weight: bold;
  }
  
  .job-details {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    font-size: 14px;
    color: #666;
  }
  
  .job-detail {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #000000;
    font-family: 'Montserrat';
    font-weight: 400;
  }
  
  .job-footer {
    margin-top: 1rem;
  }
  
  .job-footer p {
    font-size: 12px;
    color: #000000;
    font-family: 'Montserrat';
    font-weight: 400;
  }
  
  .candidates {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .candidates img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .candidates span {
    font-size: 12px;
    color: #666;
  }
  