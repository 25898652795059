.sobre-nos-container{
    background-color:#df622e;
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap: 10px;
    flex-direction:column;
    padding:10px; 
    height:auto;
}
.sobre-nos-container h3{
    font-family:'Montserrat';
    font-size:25px ;
    color:#fdf9f0 ;
    margin:10px;
}
.sobre-nos-container h1{
    font-family:'Montserrat' ;
    font-size:45px;
    color:#fdf9f0 ;
    font-weight:bold;
    margin:-20px;
}
.linkLogin{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 15px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #fdf9f0;
    padding: 10px 15px 10px 20px;
    border: solid 1px #fdf9f0;
    border-radius: 5rem;
    margin-top: 15px;
    text-decoration: none;
    transition: all 1s;

}
.linkLogin:hover{
    color: #fdf9f0;
    background-color: #942c00;
}

@media screen and (max-width:440px) {
    .sobre-nos-container{
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        gap: 5px;
        
    }
    .sobre-nos-container h3{
        font-size: 13px;
    }
    .sobre-nos-container h1{
        font-size: 40px;
        padding: 15px;
        
    }
    
}