.search-bar-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
}

/* Filtros */
.filters {
  display: flex;
  gap: 15px;
}

.filter {
  display: flex;
  align-items: center;
  gap: 8px;
  height: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-family: 'Montserrat';
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.filter-icon {
  font-size: 18px;
  color: #555;
}

.filter select {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
}

/* Área de pesquisa */
.search-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.location {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-family: 'Montserrat';
}
.location select {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
}

.location-icon {
  font-size: 18px;
  color: #555;
}

.search-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  color: #000;
}
.search-input::placeholder {
  color: #000; /* Cor do placeholder */
  font-style: italic; /* Opcional: deixa o texto em itálico */
  opacity: 1; 
}

.search-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-button:hover {
  background-color: #0056b3;
}

.reset-button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 18px;
}

.reset-button:hover {
  background-color: #f2f2f2;
}

@media screen and (max-width: 440px) {
  .filters {
    flex-direction: column;
    height: auto;
  }
  .search-bar-container{ 
    height: auto;
    align-items:center;

    width: 84%;

  }
  .search-section {
    flex-direction: column;
  }
}
@media screen and (max-width: 390px) {
  .filters {
    flex-direction: column;
    height: auto;
  }
  .search-bar-container{ 
    height: auto;
    align-items:center;

    width: 92%;

  }
  .search-section {
    flex-direction: column;
  }
}