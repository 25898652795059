::placeholder {
    color: rgb(233, 233, 233);
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: rgb(233, 233, 233);
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: rgb(233, 233, 233);
  }