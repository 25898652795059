
.modal {
    width: 55%;
    position: absolute;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
    background-color: #EDE8EE;
    padding: 15px;
    text-align: center;
    border-radius: 1vh;
    justify-content: center;
  }
  
  .paper {
    width: 100%;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container {
    background-color: #f27420;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 99%;
    padding: 15px;
    border-radius: 1vh;
  }
  
  .field {
    text-align: left;
    padding: 5px;
  }
  
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f27420; 
    margin-top: 20px;
    padding: 6px 10px;
  }
  
  .final-button{
      height: 40px;
       background-color: #f27420;
        margin-top: 50px;
         margin-bottom: 10px
  }