@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');




.containerProgramas {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 5em;
}

.title_programas {
    font-size: 3.5em;
    font-weight: bold;
    color: #df622e;
    font-family: 'Montserrat', serif;
    font-weight: bold;
    font-optical-sizing: auto;
}

.lab {
    background-color: #df622e;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    height: 340px;
    font-family: 'Montserrat', serif;
}

.lab img {
    width: 508px;
    height: 508px;
    object-fit: cover;
}

.lab h3 {
    color: #df622e;
}

.work {
    background-color: #255aa7;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 340px;
    height: 340px;
    overflow: hidden;
}

.work img {

    width: 340px;
    height: auto;
    object-fit: cover;
}

.work h3 {
    color: #255aa7;
}

.trilhas {
    background-color: #b53231;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 340px;
    height: 340px;
}

.trilhas img {
    width: 370px;
    height: auto;
    object-fit: cover;
    margin-top: 1.5rem;
}

.trilhas h3 {
    color: #b53231;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
}

.titleCards {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
}

.textCard {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 12rem;
    text-align: left;
}

.modalText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 35%;
    gap: 10px;
    text-align: left;
    margin-left: 10px;
}

.modalText img {
    width: 25rem;
    height: 90px;
    margin-bottom: 2rem;
    object-fit: cover;
}

.linkCadastro {
    text-decoration: none;
    color: white;
    font-family: 'Inter', sans-serif;
    text-transform: none;
    font-size: 14px;
    margin: 10px;
    border-radius: 50px;
    background: #e7932e;
    padding: 10px;
}

.linkCadastro:hover {
    text-decoration: none;
    color: #fdf9f0;
    background: transparent;
    border: 2px solid;
    transition: ease-in-out 0.3s;
}

.linkTurmasModal {
    text-decoration: none;
    color: white;
    font-family: 'Inter', sans-serif;
    text-transform: none;
    font-size: 14px;
    margin: 10px;
    border-radius: 50px;
    background: transparent;
    padding: 10px;
    border: solid 1px #fdf9f0
}

.linkTurmasModal:hover {
    border: 0;
    background: #e7932e;
    color: #fdf9f0;
    transition: ease-in-out 0.3s
}

.cardContainer {
    position: relative;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
}

.cardLogoContainer {
    width: 160px;
    height: 48px;
    padding: 6px;
    border-radius: 5rem;
    background-color: #fdf9f0;
    position: absolute;
    bottom: 70px;
    /* Espaçamento inferior */
    left: 190px;
    /* Centraliza horizontalmente */
    transform: translateX(-50%);
    object-fit: contain;
}
.img_modal_person{
    height: 45rem;
    width: 50%; 
    overflow: hidden; 
    display: flex;
    align-items: center; 
    justify-content: center;
}

@media (max-width: 1150px) {
    .title_programas {
        font-size: 3em;
        text-align: center;
    }
}

@media (max-width: 720px) {
    .cardContainer {
        flex-direction: column;
        /* Altere para coluna */
    }

    .textCard {
        width: 70%;
    }

    .title_programas {
        font-size: 3em;
        text-align: center;
    }
    .cardLogoContainer {
       
        bottom: 45%;
        /* Espaçamento inferior */
        left: 50%;
        /* Centraliza horizontalmente */
      
    }
   
}
@media (max-width: 550px){
    .cardLogoContainer {
       
        top: 270px;
        /* Espaçamento inferior */
        
        /* Centraliza horizontalmente */
      
    }
}
@media (max-width: 430px) {
    .cardContainer {
        flex-direction: column;
        /* Altere para coluna */
        width: 100%;
    }

    .textCard {
        width: 90%;
    }

    .title_programas {
        font-size: 2em;
        text-align: center;
    }
    .img_modal_person{
        display: none;
       
    }
    .modalText{
        width: 90%;
    }
    .modalText img{
        width: 100%;
        object-fit: contain;
        overflow: visible;
    }
}
