.containerMapa {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: auto;
    width: 100%;
    flex-wrap: wrap;
    background-color: #fdf9f0;


}

.imgMapaContainer {
    width: 60%;
    position: relative;


}

.textMapaContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    width: 30%;

}

.titulo {
    color: #df622e;
    font-size: 3.5em;
    font-family: 'Montserrat';
    font-weight: bold;
    line-height: 1.1;
}

.subtituloMapa {
    font-family: 'Montserrat';
    font-size: 1.03em;
    color: black;
    font-weight: 400;
}

.waves {
    display: flex;
    flex-direction: column;
    gap: 3px;
    /* Espaçamento entre as ondas */
    position: absolute;
    bottom: 70px;
    left: 60px;
}


.wave {
    width: 120px;
    /* Largura da onda */
    height: 20px;
    /* Altura da onda */
    fill: #e8b545;
    /* Cor amarela */
}

/* Meio-círculos vermelhos */
.semi-circle {
    position: absolute;
    right: 50px;
    bottom: 160px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.circle {
    width: 50px;
    height: 30px;
    background-color: #d43c3c;
    /* Vermelho */
    border-radius: 40px 40px 0 0;
    /* Apenas o topo é arredondado */
}

.linkMapa {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #fdf9f0;
    padding: 10px 15px 10px 20px;
    background-color: #df622e;
    border-radius: 5rem;
    margin-top: 15px;
    width: 160px;
    transition: all 1s;

}

.linkMapa:hover {
    text-decoration: none;
    background-color: #942c00;
    color: #fdf9f0;

}

@media (max-width: 1300px) {
    .containerMapa {
        flex-direction: column;
    }

    .imgMapaContainer {
        width: 70vw;
        margin-top: 40px;
    }

    .textMapaContainer {
        width: 40%;
        align-items: center;
        text-align: center;

    }
}

@media (max-width: 900px) {

    .imgMapaContainer {
        width: 100vw;
        margin-top: 40px;

    }

    .textMapaContainer {
        width: 70%;
        align-items: center;
        text-align: center;

    }

    .semi-circle {
        bottom: 100px;
        right: 30px;
    }
}

@media (max-width: 700px) {

    .titulo {
        font-size: 2.5em;
    }
    .subtituloMapa{
        font: 0.7em;
    }
    .semi-circle {
        display: none;
    }

}
@media (max-width: 500px) {

    .textMapaContainer {
        width: 90%;

    }

}