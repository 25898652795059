.headerSecti{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    background-color: #255aa7;
    height: 6vh;
    text-align: center;
    z-index: 1000;
    
}
.imgLogoAl{
  width: 120px; /* Largura desejada */
  height: 40px; /* Altura desejada */
  overflow: hidden; /* Esconde partes excedentes */
  display: flex;
  justify-content: center;
  align-items: center;
  

}
.imgLogoAl img{
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.titleHeaderSecti{
    font-size: 25px;
    color: #fdf9f0;
    font-weight: bold;
}
.linkSiteSecti{
    text-decoration: none;
    font-size: 11px;
    color: #fdf9f0;
}
.iconsSectiContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.4em;
}