/* .containerInovacaoData {
  font-family: "Montserrat";
  background-color: #FFF;
  padding: 20px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.metrics {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  width: 70%;
  margin-top: 3rem;
}

.extra-margin {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  width: 70%;
  margin-top: 4rem;
}

.metric {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  text-align: left;
  gap: 1em;
  line-height: 1;
}

.metric-icon {
  font-size: 40px;
  color: #df622e;

}

.metric h2 {
  font-size: 2.7em;
  font-family: 'Montserrat';
  font-weight: bold;
  margin: 10px 0;
  color: #000000;
}

.metric p {
  color: #555;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
}


.main-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 80%;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.image-section {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.central-image {
  width: 100%;
  border-radius: 8px;
}
.background-circle{
  position: absolute;
  width: 500px; 
  height: 500px; 
  background-color: #fdf5e0; 
  border-radius: 50%; 
  z-index: -1; 
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%); 
}



.text-section {
  width: 40%;
}

.text-section h1 {
  color: #df622e;
  font-size: 5em;
  font-family: 'Montserrat';
  line-height: 1;
  margin-bottom: 20px;
  font-weight: bold;
}

.text-section p {
  color: #555;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  text-align: left;
  line-height: 1.6;
}

.text-section span {
  color: #b53231;
  font-weight: bold;
}


.target-audience {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.audience-card {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 170px;
  height: 170px;
  
}
.audience-icon {
  font-size: 60px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 2rem;
  display: inline-block;
}

.audience-icon.blue {
  background-color: #255aa7;
  color: #fff;
}
.audience-icon.blue p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}


.audience-icon.yellow {
  background-color: #e8b545;
  color: #fff;
}
.audience-icon.yellow p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}

.audience-icon.red {
  background-color: #b53231;
  color: #fff;
}
.audience-icon.red p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}

.audience-card p {
  font-size: 14px;
  color: #555;
}

.iconText {
  color: #FFF;
}


.speech-bubble {
  position: absolute;
  padding: 10px 30px;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: bold;

  
}
.video p{
  font-size: 16px;
  font-family: 'Montserrat';
}
.video {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 200px;
  left: 10px;
  background-color: #df622e;
  color: #fff;
}

.video::after {
  content: "";
  position: absolute;
  top: 50%; 
  right: -8px; 
  transform: translateY(-50%); 
  border-width: 15px 0 15px 15px; 
  border-style: solid;
  border-color: transparent transparent transparent #df622e; 
}

.contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 80px;
  right: 50px;
  background-color: #255aa7;
  color: #fff;
}
.contacts p{
  font-size: 16px;
  font-family: 'Montserrat';
}
.contacts::after {
  content: "";
  position: absolute;
  top: 50%; 
  left: -8px; 
  transform: translateY(-50%); 
  border-width: 10px 10px 10px 0; 
  border-style: solid;
  border-color: transparent #255aa7 transparent transparent ; 
}


@media screen and (max-width:1640px) {
  .main-section{
    flex-direction: column;
  }
  .video{
    top: 160px;
    left: -50px;
  }
  .contacts{
    top: 70px;
    left: -50px;
  }
} */

.containerInovacaoData {
  font-family: "Montserrat";
  background-color: #FFF;
  padding: 20px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

/* Seção de métricas */
.metrics {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  width: 70%;
  margin-top: 3rem;
}

.extra-margin {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  width: 70%;
  margin-top: 4rem;
}

.metric {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  text-align: left;
  gap: 1em;
  line-height: 1;
}

.metric-icon {
  font-size: 40px;
  color: #df622e;

}

.metric h2 {
  font-size: 2.7em;
  font-family: 'Montserrat';
  font-weight: bold;
  margin: 10px 0;
  color: #000000;
}

.metric p {
  color: #555;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
}

/* Seção principal */
.main-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 80%;
  margin-top: 2rem;
  flex-wrap: wrap;
}

/* Imagem central */
.image-section {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.central-image {
  width: 100%;
  border-radius: 8px;
}
.background-circle{
  position: absolute;
  width: 500px; /* Largura do círculo */
  height: 500px; /* Altura do círculo */
  background-color: #fdf5e0; /* Cor do círculo */
  border-radius: 50%; /* Faz o círculo */
  z-index: -1; /* Coloca atrás da foto */
  top: 50%; /* Centraliza verticalmente */
  left: 50%; /* Centraliza horizontalmente */
  transform: translate(-50%, -50%); /* Ajusta a posição para o centro */
}


/* Texto descritivo */
.text-section {
  width: 40%;
}

.text-section h1 {
  color: #df622e;
  font-size: 5em;
  font-family: 'Montserrat';
  line-height: 1;
  margin-bottom: 20px;
  font-weight: bold;
}

.text-section p {
  color: #555;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  text-align: left;
  line-height: 1.6;
}

.text-section span {
  color: #b53231;
  font-weight: bold;
}

/* Público-alvo */
.target-audience {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.audience-card {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 170px;
  height: 170px;
  
}
.audience-icon {
  font-size: 60px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 2rem;
  display: inline-block;
}

.audience-icon.blue {
  background-color: #255aa7;
  color: #fff;
}
.audience-icon.blue p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}


.audience-icon.yellow {
  background-color: #e8b545;
  color: #fff;
}
.audience-icon.yellow p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}

.audience-icon.red {
  background-color: #b53231;
  color: #fff;
}
.audience-icon.red p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}

.audience-card p {
  font-size: 14px;
  color: #555;
}

.iconText {
  color: #FFF;
}


.speech-bubble {
  position: absolute;
  padding: 10px 30px;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: bold;
}
.video p{
  font-size: 16px;
  font-family: 'Montserrat';
}
.video {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 200px;
  left: 10px;
  background-color: #df622e;
  color: #fff;
}

.video::after {
  content: "";
  position: absolute;
  top: 50%; /* Centraliza verticalmente no balão */
  right: -8px; /* Posiciona o triângulo na borda esquerda */
  transform: translateY(-50%); /* Ajusta para o centro vertical */
  border-width: 15px 0 15px 15px; /* Tamanho do triângulo */
  border-style: solid;
  border-color: transparent transparent transparent #df622e; /* Cor do triângulo */
}

.contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 80px;
  right: 50px;
  background-color: #255aa7;
  color: #fff;
}
.contacts p{
  font-size: 16px;
  font-family: 'Montserrat';
}
.contacts::after {
  content: "";
  position: absolute;
  top: 50%; /* Centraliza verticalmente no balão */
  left: -8px; /* Posiciona o triângulo na borda direita */
  transform: translateY(-50%); /* Ajusta para o centro vertical */
  border-width: 10px 10px 10px 0; /* Tamanho do triângulo */
  border-style: solid;
  border-color: transparent #255aa7 transparent transparent ; /* Cor do triângulo */
}


@media screen and (max-width:440px){
  .containerInovacaoData{
    margin-top: 50rem;
  }
  .main-section{
    flex-direction: column;
    width: 100%;
    
  }
  .text-section {
    width: 90%;
  }
  .text-section h1 {
    font-size: 3.5em;
    
  }
  .target-audience {
    width: 100%;
  }
  
  .audience-card {
    width: 17%;
    height: 17%;
  }
  .image-section {
    width: 100%;
  }
  
  .central-image {
    width: 100%;
    
  }
  .background-circle{
   
    width: 90%; /* Largura do círculo */
    height: 90%; /* Altura do círculo */
    
  }
  .speech-bubble {
    
    padding: 5px 15px;
    
  }
  .video p{
    font-size: 13px;

  }
  .video {
    
    left: 0;
    
  }
  
  .contacts {
  
    right: 0;
   
  }
  .contacts p{
    font-size: 13px;

  }
  
  
}
@media screen and (max-width:390px){
  .containerInovacaoData{
    margin-top: 55rem;
  }
  .audience-card {
    width: 15%;
    height: 15%;
    
  }
  .audience-icon {
    font-size: 40px;
    
  }
  .audience-card .audience-icon p{
    font-size: 13px;
    
  }
  
}


