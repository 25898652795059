@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);
.search-bar-container {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
}

/* Filtros */
.filters {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}

.filter {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  height: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-family: 'Montserrat';
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.filter-icon {
  font-size: 18px;
  color: #555;
}

.filter select {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
}

/* Área de pesquisa */
.search-section {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.location {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-family: 'Montserrat';
}
.location select {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
}

.location-icon {
  font-size: 18px;
  color: #555;
}

.search-input {
  flex: 1 1;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  color: #000;
}
.search-input::placeholder {
  color: #000; /* Cor do placeholder */
  font-style: italic; /* Opcional: deixa o texto em itálico */
  opacity: 1; 
}

.search-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-button:hover {
  background-color: #0056b3;
}

.reset-button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 18px;
}

.reset-button:hover {
  background-color: #f2f2f2;
}

@media screen and (max-width: 440px) {
  .filters {
    flex-direction: column;
    height: auto;
  }
  .search-bar-container{ 
    height: auto;
    align-items:center;

    width: 84%;

  }
  .search-section {
    flex-direction: column;
  }
}
@media screen and (max-width: 390px) {
  .filters {
    flex-direction: column;
    height: auto;
  }
  .search-bar-container{ 
    height: auto;
    align-items:center;

    width: 92%;

  }
  .search-section {
    flex-direction: column;
  }
}
.containerMapa {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: auto;
    width: 100%;
    flex-wrap: wrap;
    background-color: #fdf9f0;


}

.imgMapaContainer {
    width: 60%;
    position: relative;


}

.textMapaContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    width: 30%;

}

.titulo {
    color: #df622e;
    font-size: 3.5em;
    font-family: 'Montserrat';
    font-weight: bold;
    line-height: 1.1;
}

.subtituloMapa {
    font-family: 'Montserrat';
    font-size: 1.03em;
    color: black;
    font-weight: 400;
}

.waves {
    display: flex;
    flex-direction: column;
    grid-gap: 3px;
    gap: 3px;
    /* Espaçamento entre as ondas */
    position: absolute;
    bottom: 70px;
    left: 60px;
}


.wave {
    width: 120px;
    /* Largura da onda */
    height: 20px;
    /* Altura da onda */
    fill: #e8b545;
    /* Cor amarela */
}

/* Meio-círculos vermelhos */
.semi-circle {
    position: absolute;
    right: 50px;
    bottom: 160px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.circle {
    width: 50px;
    height: 30px;
    background-color: #d43c3c;
    /* Vermelho */
    border-radius: 40px 40px 0 0;
    /* Apenas o topo é arredondado */
}

.linkMapa {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #fdf9f0;
    padding: 10px 15px 10px 20px;
    background-color: #df622e;
    border-radius: 5rem;
    margin-top: 15px;
    width: 160px;
    transition: all 1s;

}

.linkMapa:hover {
    text-decoration: none;
    background-color: #942c00;
    color: #fdf9f0;

}

@media (max-width: 1300px) {
    .containerMapa {
        flex-direction: column;
    }

    .imgMapaContainer {
        width: 70vw;
        margin-top: 40px;
    }

    .textMapaContainer {
        width: 40%;
        align-items: center;
        text-align: center;

    }
}

@media (max-width: 900px) {

    .imgMapaContainer {
        width: 100vw;
        margin-top: 40px;

    }

    .textMapaContainer {
        width: 70%;
        align-items: center;
        text-align: center;

    }

    .semi-circle {
        bottom: 100px;
        right: 30px;
    }
}

@media (max-width: 700px) {

    .titulo {
        font-size: 2.5em;
    }
    .subtituloMapa{
        font: 0.7em;
    }
    .semi-circle {
        display: none;
    }

}
@media (max-width: 500px) {

    .textMapaContainer {
        width: 90%;

    }

}
.sobre-nos-container{
    background-color:#df622e;
    display:flex;
    align-items:center;
    justify-content:space-between;
    grid-gap: 10px;
    gap: 10px;
    flex-direction:column;
    padding:10px; 
    height:auto;
}
.sobre-nos-container h3{
    font-family:'Montserrat';
    font-size:25px ;
    color:#fdf9f0 ;
    margin:10px;
}
.sobre-nos-container h1{
    font-family:'Montserrat' ;
    font-size:45px;
    color:#fdf9f0 ;
    font-weight:bold;
    margin:-20px;
}
.linkLogin{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 15px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #fdf9f0;
    padding: 10px 15px 10px 20px;
    border: solid 1px #fdf9f0;
    border-radius: 5rem;
    margin-top: 15px;
    text-decoration: none;
    transition: all 1s;

}
.linkLogin:hover{
    color: #fdf9f0;
    background-color: #942c00;
}

@media screen and (max-width:440px) {
    .sobre-nos-container{
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        grid-gap: 5px;
        gap: 5px;
        
    }
    .sobre-nos-container h3{
        font-size: 13px;
    }
    .sobre-nos-container h1{
        font-size: 40px;
        padding: 15px;
        
    }
    
}
.logos {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.headerSecti{
    display: flex;
    align-items: center;
    justify-content: space-around;
    grid-gap: 2rem;
    gap: 2rem;
    background-color: #255aa7;
    height: 6vh;
    text-align: center;
    z-index: 1000;
    
}
.imgLogoAl{
  width: 120px; /* Largura desejada */
  height: 40px; /* Altura desejada */
  overflow: hidden; /* Esconde partes excedentes */
  display: flex;
  justify-content: center;
  align-items: center;
  

}
.imgLogoAl img{
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.titleHeaderSecti{
    font-size: 25px;
    color: #fdf9f0;
    font-weight: bold;
}
.linkSiteSecti{
    text-decoration: none;
    font-size: 11px;
    color: #fdf9f0;
}
.iconsSectiContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 0.4em;
    gap: 0.4em;
}
.containerProgramas {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 5em;
}

.title_programas {
    font-size: 3.5em;
    font-weight: bold;
    color: #df622e;
    font-family: 'Montserrat', serif;
    font-weight: bold;
    font-optical-sizing: auto;
}

.lab {
    background-color: #df622e;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    height: 340px;
    font-family: 'Montserrat', serif;
}

.lab img {
    width: 508px;
    height: 508px;
    object-fit: cover;
}

.lab h3 {
    color: #df622e;
}

.work {
    background-color: #255aa7;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 340px;
    height: 340px;
    overflow: hidden;
}

.work img {

    width: 340px;
    height: auto;
    object-fit: cover;
}

.work h3 {
    color: #255aa7;
}

.trilhas {
    background-color: #b53231;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 340px;
    height: 340px;
}

.trilhas img {
    width: 370px;
    height: auto;
    object-fit: cover;
    margin-top: 1.5rem;
}

.trilhas h3 {
    color: #b53231;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
}

.titleCards {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
}

.textCard {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 12rem;
    text-align: left;
}

.modalText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 35%;
    grid-gap: 10px;
    gap: 10px;
    text-align: left;
    margin-left: 10px;
}

.modalText img {
    width: 25rem;
    height: 90px;
    margin-bottom: 2rem;
    object-fit: cover;
}

.linkCadastro {
    text-decoration: none;
    color: white;
    font-family: 'Inter', sans-serif;
    text-transform: none;
    font-size: 14px;
    margin: 10px;
    border-radius: 50px;
    background: #e7932e;
    padding: 10px;
}

.linkCadastro:hover {
    text-decoration: none;
    color: #fdf9f0;
    background: transparent;
    border: 2px solid;
    transition: ease-in-out 0.3s;
}

.linkTurmasModal {
    text-decoration: none;
    color: white;
    font-family: 'Inter', sans-serif;
    text-transform: none;
    font-size: 14px;
    margin: 10px;
    border-radius: 50px;
    background: transparent;
    padding: 10px;
    border: solid 1px #fdf9f0
}

.linkTurmasModal:hover {
    border: 0;
    background: #e7932e;
    color: #fdf9f0;
    transition: ease-in-out 0.3s
}

.cardContainer {
    position: relative;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
}

.cardLogoContainer {
    width: 160px;
    height: 48px;
    padding: 6px;
    border-radius: 5rem;
    background-color: #fdf9f0;
    position: absolute;
    bottom: 70px;
    /* Espaçamento inferior */
    left: 190px;
    /* Centraliza horizontalmente */
    transform: translateX(-50%);
    object-fit: contain;
}
.img_modal_person{
    height: 45rem;
    width: 50%; 
    overflow: hidden; 
    display: flex;
    align-items: center; 
    justify-content: center;
}

@media (max-width: 1150px) {
    .title_programas {
        font-size: 3em;
        text-align: center;
    }
}

@media (max-width: 720px) {
    .cardContainer {
        flex-direction: column;
        /* Altere para coluna */
    }

    .textCard {
        width: 70%;
    }

    .title_programas {
        font-size: 3em;
        text-align: center;
    }
    .cardLogoContainer {
       
        bottom: 45%;
        /* Espaçamento inferior */
        left: 50%;
        /* Centraliza horizontalmente */
      
    }
   
}
@media (max-width: 550px){
    .cardLogoContainer {
       
        top: 270px;
        /* Espaçamento inferior */
        
        /* Centraliza horizontalmente */
      
    }
}
@media (max-width: 430px) {
    .cardContainer {
        flex-direction: column;
        /* Altere para coluna */
        width: 100%;
    }

    .textCard {
        width: 90%;
    }

    .title_programas {
        font-size: 2em;
        text-align: center;
    }
    .img_modal_person{
        display: none;
       
    }
    .modalText{
        width: 90%;
    }
    .modalText img{
        width: 100%;
        object-fit: contain;
        overflow: visible;
    }
}

/* .containerInovacaoData {
  font-family: "Montserrat";
  background-color: #FFF;
  padding: 20px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.metrics {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  width: 70%;
  margin-top: 3rem;
}

.extra-margin {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  width: 70%;
  margin-top: 4rem;
}

.metric {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  text-align: left;
  gap: 1em;
  line-height: 1;
}

.metric-icon {
  font-size: 40px;
  color: #df622e;

}

.metric h2 {
  font-size: 2.7em;
  font-family: 'Montserrat';
  font-weight: bold;
  margin: 10px 0;
  color: #000000;
}

.metric p {
  color: #555;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
}


.main-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 80%;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.image-section {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.central-image {
  width: 100%;
  border-radius: 8px;
}
.background-circle{
  position: absolute;
  width: 500px; 
  height: 500px; 
  background-color: #fdf5e0; 
  border-radius: 50%; 
  z-index: -1; 
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%); 
}



.text-section {
  width: 40%;
}

.text-section h1 {
  color: #df622e;
  font-size: 5em;
  font-family: 'Montserrat';
  line-height: 1;
  margin-bottom: 20px;
  font-weight: bold;
}

.text-section p {
  color: #555;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  text-align: left;
  line-height: 1.6;
}

.text-section span {
  color: #b53231;
  font-weight: bold;
}


.target-audience {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.audience-card {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 170px;
  height: 170px;
  
}
.audience-icon {
  font-size: 60px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 2rem;
  display: inline-block;
}

.audience-icon.blue {
  background-color: #255aa7;
  color: #fff;
}
.audience-icon.blue p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}


.audience-icon.yellow {
  background-color: #e8b545;
  color: #fff;
}
.audience-icon.yellow p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}

.audience-icon.red {
  background-color: #b53231;
  color: #fff;
}
.audience-icon.red p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}

.audience-card p {
  font-size: 14px;
  color: #555;
}

.iconText {
  color: #FFF;
}


.speech-bubble {
  position: absolute;
  padding: 10px 30px;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: bold;

  
}
.video p{
  font-size: 16px;
  font-family: 'Montserrat';
}
.video {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 200px;
  left: 10px;
  background-color: #df622e;
  color: #fff;
}

.video::after {
  content: "";
  position: absolute;
  top: 50%; 
  right: -8px; 
  transform: translateY(-50%); 
  border-width: 15px 0 15px 15px; 
  border-style: solid;
  border-color: transparent transparent transparent #df622e; 
}

.contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 80px;
  right: 50px;
  background-color: #255aa7;
  color: #fff;
}
.contacts p{
  font-size: 16px;
  font-family: 'Montserrat';
}
.contacts::after {
  content: "";
  position: absolute;
  top: 50%; 
  left: -8px; 
  transform: translateY(-50%); 
  border-width: 10px 10px 10px 0; 
  border-style: solid;
  border-color: transparent #255aa7 transparent transparent ; 
}


@media screen and (max-width:1640px) {
  .main-section{
    flex-direction: column;
  }
  .video{
    top: 160px;
    left: -50px;
  }
  .contacts{
    top: 70px;
    left: -50px;
  }
} */

.containerInovacaoData {
  font-family: "Montserrat";
  background-color: #FFF;
  padding: 20px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

/* Seção de métricas */
.metrics {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
  width: 70%;
  margin-top: 3rem;
}

.extra-margin {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
  width: 70%;
  margin-top: 4rem;
}

.metric {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  text-align: left;
  grid-gap: 1em;
  gap: 1em;
  line-height: 1;
}

.metric-icon {
  font-size: 40px;
  color: #df622e;

}

.metric h2 {
  font-size: 2.7em;
  font-family: 'Montserrat';
  font-weight: bold;
  margin: 10px 0;
  color: #000000;
}

.metric p {
  color: #555;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
}

/* Seção principal */
.main-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  width: 80%;
  margin-top: 2rem;
  flex-wrap: wrap;
}

/* Imagem central */
.image-section {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.central-image {
  width: 100%;
  border-radius: 8px;
}
.background-circle{
  position: absolute;
  width: 500px; /* Largura do círculo */
  height: 500px; /* Altura do círculo */
  background-color: #fdf5e0; /* Cor do círculo */
  border-radius: 50%; /* Faz o círculo */
  z-index: -1; /* Coloca atrás da foto */
  top: 50%; /* Centraliza verticalmente */
  left: 50%; /* Centraliza horizontalmente */
  transform: translate(-50%, -50%); /* Ajusta a posição para o centro */
}


/* Texto descritivo */
.text-section {
  width: 40%;
}

.text-section h1 {
  color: #df622e;
  font-size: 5em;
  font-family: 'Montserrat';
  line-height: 1;
  margin-bottom: 20px;
  font-weight: bold;
}

.text-section p {
  color: #555;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  text-align: left;
  line-height: 1.6;
}

.text-section span {
  color: #b53231;
  font-weight: bold;
}

/* Público-alvo */
.target-audience {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.audience-card {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 170px;
  height: 170px;
  
}
.audience-icon {
  font-size: 60px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 2rem;
  display: inline-block;
}

.audience-icon.blue {
  background-color: #255aa7;
  color: #fff;
}
.audience-icon.blue p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}


.audience-icon.yellow {
  background-color: #e8b545;
  color: #fff;
}
.audience-icon.yellow p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}

.audience-icon.red {
  background-color: #b53231;
  color: #fff;
}
.audience-icon.red p{
  color: #FFF;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-align: center;
}

.audience-card p {
  font-size: 14px;
  color: #555;
}

.iconText {
  color: #FFF;
}


.speech-bubble {
  position: absolute;
  padding: 10px 30px;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: bold;
}
.video p{
  font-size: 16px;
  font-family: 'Montserrat';
}
.video {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 200px;
  left: 10px;
  background-color: #df622e;
  color: #fff;
}

.video::after {
  content: "";
  position: absolute;
  top: 50%; /* Centraliza verticalmente no balão */
  right: -8px; /* Posiciona o triângulo na borda esquerda */
  transform: translateY(-50%); /* Ajusta para o centro vertical */
  border-width: 15px 0 15px 15px; /* Tamanho do triângulo */
  border-style: solid;
  border-color: transparent transparent transparent #df622e; /* Cor do triângulo */
}

.contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 80px;
  right: 50px;
  background-color: #255aa7;
  color: #fff;
}
.contacts p{
  font-size: 16px;
  font-family: 'Montserrat';
}
.contacts::after {
  content: "";
  position: absolute;
  top: 50%; /* Centraliza verticalmente no balão */
  left: -8px; /* Posiciona o triângulo na borda direita */
  transform: translateY(-50%); /* Ajusta para o centro vertical */
  border-width: 10px 10px 10px 0; /* Tamanho do triângulo */
  border-style: solid;
  border-color: transparent #255aa7 transparent transparent ; /* Cor do triângulo */
}


@media screen and (max-width:440px){
  .containerInovacaoData{
    margin-top: 50rem;
  }
  .main-section{
    flex-direction: column;
    width: 100%;
    
  }
  .text-section {
    width: 90%;
  }
  .text-section h1 {
    font-size: 3.5em;
    
  }
  .target-audience {
    width: 100%;
  }
  
  .audience-card {
    width: 17%;
    height: 17%;
  }
  .image-section {
    width: 100%;
  }
  
  .central-image {
    width: 100%;
    
  }
  .background-circle{
   
    width: 90%; /* Largura do círculo */
    height: 90%; /* Altura do círculo */
    
  }
  .speech-bubble {
    
    padding: 5px 15px;
    
  }
  .video p{
    font-size: 13px;

  }
  .video {
    
    left: 0;
    
  }
  
  .contacts {
  
    right: 0;
   
  }
  .contacts p{
    font-size: 13px;

  }
  
  
}
@media screen and (max-width:390px){
  .containerInovacaoData{
    margin-top: 55rem;
  }
  .audience-card {
    width: 15%;
    height: 15%;
    
  }
  .audience-icon {
    font-size: 40px;
    
  }
  .audience-card .audience-icon p{
    font-size: 13px;
    
  }
  
}



.logos {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    @media (max-width: 320px) {
        width: 50%;
    }
}



.card-descricao ol{
    padding-left: 22px;
}
.MuiSelect-iconStandard, .MuiSelect-standard{
    color: white !important;
}

.MuiTooltip-tooltipPlacementTop{
    background-color: black !important;
}

.MuiTooltip-arrow{
    color: black !important;
}
::placeholder {
    color: rgb(233, 233, 233);
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: rgb(233, 233, 233);
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: rgb(233, 233, 233);
  }
.MuiSelect-iconStandard, .MuiSelect-standard{
    color: white !important;
}

.MuiTooltip-tooltipPlacementTop{
    background-color: black !important;
}

.MuiTooltip-arrow{
    color: black !important;
}
::placeholder {
    color: rgb(233, 233, 233);
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: rgb(233, 233, 233);
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: rgb(233, 233, 233);
  }
.card-descricao ol{
    padding-left: 22px;
}
.ant-card-meta-description{
    color: black !important;
}

.modal {
    width: 55%;
    position: absolute;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
    background-color: #EDE8EE;
    padding: 15px;
    text-align: center;
    border-radius: 1vh;
    justify-content: center;
  }
  
  .paper {
    width: 100%;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container {
    background-color: #f27420;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 99%;
    padding: 15px;
    border-radius: 1vh;
  }
  
  .field {
    text-align: left;
    padding: 5px;
  }
  
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f27420; 
    margin-top: 20px;
    padding: 6px 10px;
  }
  
  .final-button{
      height: 40px;
       background-color: #f27420;
        margin-top: 50px;
         margin-bottom: 10px
  }

.modal {
    width: 55%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #EDE8EE;
    padding: 15px;
    text-align: center;
    border-radius: 1vh;
    justify-content: center;
  }
  
  .paper {
    width: 100%;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container {
    background-color: #DFD3E4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 99%;
    padding: 15px;
    border-radius: 1vh;
  }
  
  .field {
    text-align: left;
    padding: 5px;
  }
  
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8451cc; 
    margin-top: 20px;
    padding: 6px 10px;
  }
  
  .final-button{
      height: 40px;
       background-color: #8451cc;
        margin-top: 50px;
         margin-bottom: 10px
  }
.job-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px; /* Espaçamento entre os cards */
    justify-content: space-between; /* Distribui espaço uniformemente */
    padding: 20px;
    width: 80%;
  }

.job-card {
    width: 100%;
    max-width: 400px;
    border: 1px solid #ddd;
    flex-direction: row;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: #fff;
    overflow: hidden;
    font-family: 'Montserrat';
    margin-top: 20px;

  }
  
  .job-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #0056a4;
    color: #fff;
  }
  
  .job-logo img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .job-buttons a {
    text-decoration: none;
    margin-left: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 500;
  }

  .job-buttons a:hover{
    color: #fff;
  }
  
  .apply-button {
    background-color: #df622e;
    color: #fff;
  }
  
  .details-button {
    background-color: #fff;
    color: #0056a4;
    border: 1px solid #0056a4;
  }
  
  .job-card-body {
    padding: 1rem;
  }
  
  .job-card-body h3 {
    font-size: 18px;
    color: #0056a4;
    margin-bottom: 0.5rem;
    font-family: 'Montserrat';
    font-weight: bold;
  }
  
  .job-card-body p {
    font-size: 14px;
    color: #000000;
    font-family: 'Montserrat';
    font-weight: 400;
  }
  
  .job-card-body .highlight {
    color: #df622e;
    font-weight: bold;
  }
  
  .job-details {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    font-size: 14px;
    color: #666;
  }
  
  .job-detail {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    color: #000000;
    font-family: 'Montserrat';
    font-weight: 400;
  }
  
  .job-footer {
    margin-top: 1rem;
  }
  
  .job-footer p {
    font-size: 12px;
    color: #000000;
    font-family: 'Montserrat';
    font-weight: 400;
  }
  
  .candidates {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
  }
  
  .candidates img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .candidates span {
    font-size: 12px;
    color: #666;
  }
  
