.MuiSelect-iconStandard, .MuiSelect-standard{
    color: white !important;
}

.MuiTooltip-tooltipPlacementTop{
    background-color: black !important;
}

.MuiTooltip-arrow{
    color: black !important;
}